import Mustache from 'mustache'
// eslint-disable-next-line import/no-webpack-loader-syntax
import companionPdfString from '!!raw-loader!./valuation.html'
import headerTemplate from '!!raw-loader!@/modules/pdfs/components/Header.html'
import marketOverviewTemplate from '!!raw-loader!@/modules/pdfs/components/MarketOverview.html'
import { getBatchPdfs } from '@/services/PdfService'
import { getBrandedLogoUrl, getReseller } from '@/modules/pdfs/utils/UtilityFunctions'

import store from '@/modules/auth/stores'

const FALLBACK = 'N/A'

export const getBatchPdf = (pdfs) => {
  const mappedPdfData = pdfs.map((pdf) => getPdfMapping(pdf))
  getBatchPdfs(mappedPdfData, 'companion')
}

export const getPdfUser = () => {
  const { name, organisations } = store.state.user
  const branchId = organisations[0].organisation.id
  const organisationId = organisations[0].organisation.organisation?.id
  return { name, branchId, organisationId }
}

const transformedDate = (date) => {
  if (!date) return FALLBACK
  const dateArray = date.split('-')
  const year = dateArray[0]
  const month = dateArray[1]
  const day = dateArray[2].split('T')[0]
  return `${day}/${month}/${year}`
}

const transformedListings = (listings) =>
  listings.map((listing) => ({
    listingVrm: listing.vrm,
    listingYear: listing.year,
    listingColour: listing.colour,
    listingSeller: listing.dealer.type,
    listingMileage: listing.mileage.display,
    listingPrice: listing.price.display,
    listingSellerName: listing.dealer.name,
    listingTimeOnMarket: listing.firstSeen,
    listingTransmission: listing.transmission.value,
    listingFuelType: listing.fuelType.value
  }))

const transformSimilarListingFilters = (similarListingFilters) => {
  const transformedFilters = {
    listingTypes: similarListingFilters.listingType
      .map((type) => capitalizeFirstLetter(type))
      .join(', '),
    sellerTypes: similarListingFilters.sellerType
      .map((type) => capitalizeFirstLetter(type))
      .join(', '),
    registrations: similarListingFilters.registration
      .map((registration) => registration)
      .join(', '),
    mileages:
      similarListingFilters.mileage.length > 0
        ? `${transformWithCommas(
            similarListingFilters.mileage[0]
          )} - ${transformWithCommas(similarListingFilters.mileage[1])}`
        : '',
    colours: similarListingFilters.colour
      .map((colour) => capitalizeFirstLetter(colour))
      .join(', '),
    distance:
      similarListingFilters.distance.coords.lat &&
      similarListingFilters.distance.coords.lon
        ? `${similarListingFilters.distance.distance} miles`
        : '',
    options: similarListingFilters.options
      .map((option) => capitalizeFirstLetter(option))
      .join(', ')
  }
  return transformedFilters
}

const capitalizeFirstLetter = (string) => {
  if (!string) return FALLBACK
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const transformWithCommas = (value) => {
  if (!value) return FALLBACK
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const getValuationPdfMapping = (pdfDataObject) => {
  const { request } = pdfDataObject

  const listings = transformedListings(request.listings)
  const companyLogo = getBrandedLogoUrl(request.organisation)
  const reseller = getReseller(request.organisation)

  const pdfData = {
    vrm: request.vehicleDetails.vrm,
    vehicleDescription: request.vehicleDetails.vehicleDescription,
    mileage: transformWithCommas(request.vehicleDetails.mileage),
    valuationDate: transformedDate(request.vehicleDetails.valuationDate),
    marketValue: transformWithCommas(request.vehicleDetails.marketValue),
    tradeValue: transformWithCommas(request.vehicleDetails.tradeValue),
    similarListingFilters: transformSimilarListingFilters(
      request.similarListingFilters
    ),
    sortBy: request.similarListingFilters.sortBy,
    listings,
    totalSimilarListings: request.total,
    customerReference: request.customerReference
  }

  const headerSection = Mustache.render(headerTemplate, {
    title: 'Valuation Report',
    companyLogo,
    mainLogo: reseller.logo,
  })

  const marketOverviewSection = Mustache.render(marketOverviewTemplate, {
    ...pdfData,
    additionalColumns: true
  })

  const pdfString = Mustache.render(companionPdfString, pdfData, {
    headerSection,
    marketOverviewSection
  })

  const pdfName = `${reseller.title} - ${request.vehicleDetails.vrm} - Valuation Certificate`

  return {
    pdfString,
    vrm: request.vehicleDetails.vrm,
    pdfName
  }
}
