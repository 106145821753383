<template>
  <div class="columns is-marginless is-mobile is-multiline">
    <div class="column is-12">
      <div class="columns is-vcentered is-mobile">
        <div class="column pr-0">
          <span class="icon">
            <i class="fas fa-tachometer has-text-light" />
          </span>
          <span class="has-text-dark">Mileage</span>
          <a
            class="icon is-outlined"
            @click="openModal('companion/CompanionMileage')"
            :disabled="!mileage"
            title="Edit"
            v-tippy="{ placement: 'right' }"
            v-if="!permissions.static"
          >
            <i class="fas fa-pencil is-size-7" />
          </a>
          <a
            class="icon is-outlined"
            @click="refresh('mileage')"
            title="Use today's estimate"
            v-tippy="{ placement: 'right' }"
            v-if="!permissions.static"
          >
            <i
              class="fal fa-sync is-size-7"
              :class="{
                'fa-spinner fa-pulse has-text-grey': $wait.is('mileage')
              }"
            />
          </a>
        </div>
        <div class="column has-text-right has-text-dark is-narrow">
          <p v-if="mileage">
            {{ formatNumber(mileage.value) }}
            <span v-if="mileage.isEstimated">(estimated)</span>
          </p>
          <p v-else>...</p>
        </div>
      </div>
    </div>
    <div class="column is-12 has-background-white-ter">
      <div class="columns is-vcentered is-mobile">
        <div class="column pr-0">
          <span class="icon">
            <i class="fas fa-calendar-alt has-text-light" />
          </span>
          <span class="has-text-dark">Valuation date</span>
          <a
            class="icon is-outlined"
            @click="openModal('companion/CompanionDate')"
            :disabled="!mileage"
            title="Edit"
            v-tippy="{ placement: 'right' }"
            v-if="!permissions.static && permissions.valuationDateAdjust"
          >
            <i class="fas fa-pencil is-size-7" />
          </a>
          <a
            class="icon is-outlined"
            @click="refresh('date')"
            title="Refresh to today"
            v-tippy="{ placement: 'right' }"
            v-if="!permissions.static"
          >
            <i
              class="fal fa-sync is-size-7"
              :class="{
                'fa-spinner fa-pulse has-text-grey': $wait.is('date')
              }"
            />
          </a>
        </div>
        <div class="column is-narrow has-text-right has-text-dark">
          <p v-if="date">
            {{ formatDate(date) }}
          </p>
          <p v-else>...</p>
        </div>
      </div>
    </div>
    <div class="column is-12">
      <CompanionCondition />
    </div>
    <div
      class="column is-12 has-background-white-ter"
      v-if="uuid && permissions.valuationCertificate"
    >
      <div class="columns is-vcentered is-mobile">
        <div class="column">
          <span class="icon">
            <i class="fas fa-file-certificate has-text-light" />
          </span>
          <span class="has-text-dark">Valuation certificate</span>
        </div>
        <div class="column has-text-right is-narrow">
          <button
            @click="getValuationPDF"
            class="button is-small is-rounded"
            :disabled="!isValuationDataLoaded"
            :class="{ 'is-loading': $wait.is('valuationPdf'), 'is-text': $motors, 'is-info': !$motors }"
          >
            <span>Download</span>
          </button>
        </div>
      </div>
    </div>
    <div class="column is-12">
      <div class="columns is-vcentered is-mobile">
        <div class="column">
          <span class="icon">
            <i class="fas fa-file-download has-text-light" />
          </span>
          <span class="has-text-dark">Companion report</span>
        </div>
        <div class="column has-text-right is-narrow">
          <button
            @click="getCompanionPDF"
            class="button is-small is-rounded"
            :disabled="!isCompanionDataLoaded"
            :class="{ 'is-loading': $wait.is('companionPdf'), 'is-text': $motors, 'is-info': !$motors }"
          >
            <span>Download</span>
          </button>
        </div>
      </div>
    </div>
    <div class="column is-12" v-if="showOptionsButton">
      <CompanionOptionsButton />
    </div>
    <div class="column is-12" v-if="!isIntegration">
      <p class="is-size-7 has-text-danger">
        <span class="icon is-small">
          <i class="fal fa-info-circle" />
        </span>
        <span>
          Updating the
          <span v-if="isInsurer">date,</span> mileage or condition will count as
          a separate lookup
        </span>
      </p>
    </div>
  </div>
</template>

<script>
import { formatNumber } from 'accounting'
import { mapGetters, mapActions } from 'vuex'
import { getBatchPdfs } from '@/services/PdfService'
import { formatDate } from '@/utils/date.utils.ts'
import { getCompanionPdfMapping } from '../../services/pdfs/CompanionFullPdf/index.js'
import * as companion from 'modules/companion/services'
import { getValuationPdfMapping } from '../../services/pdfs/ValuationPdf/index.js'
export default {
  name: 'CompanionValuationActions',
  components: {
    CompanionCondition: () =>
      import('modules/companion/components/section/CompanionCondition'),
    CompanionOptionsButton: () => import('./options/CompanionOptionsButton')
  },
  computed: {
    ...mapGetters({
      vrm: 'companion/vrm',
      make: 'companion/make',
      mileage: 'companion/valuationMileage',
      date: 'companion/valuationDate',
      namaGrade: 'companion/namaGrade',
      pdfValues: 'companion/pdfValues',
      isInsurer: 'auth/isInsurer',
      isIntegration: 'auth/isIntegration',
      permissions: 'auth/permissions',
      organisation: 'auth/organisation'
    }),
    ...mapGetters('companion', [
      'data',
      'vrm',
      'make',
      'model',
      'cid',
      'photos',
      'specs',
      'customerReference',
      'overview',
      'listing',
      'pricePosition',
      'type',
      'timeline'
    ]),
    ...mapGetters('companion/similarListings', [
      'listings',
      'sortBy',
      'request',
      'total'
    ]),
    uuid() {
      return this.$route.params.uuid || false
    },
    showOptionsButton() {
      const { vehicleOptions, codedVehicleOptions } = this.permissions
      const makeHasOptions = companion.makeHasOptions({ make: this.make })
      return makeHasOptions && (vehicleOptions || codedVehicleOptions)
    },
    isCompanionDataLoaded() {
      const requiredData = [
        this.vrm,
        this.data,
        this.mileage,
        this.date,
        this.request,
        this.sortBy,
        this.listings,
        this.photos,
        this.namaGrade,
        this.specs
      ]

      return requiredData.every((data) => data !== null && data !== undefined)
    },
    isValuationDataLoaded() {
      const requiredData = [
        this.vrm,
        this.data,
        this.mileage,
        this.date,
        this.request,
        this.sortBy,
        this.listings,
        this.organisation
      ]

      return requiredData.every((data) => data !== null && data !== undefined)
    }
  },
  methods: {
    ...mapActions({
      getTimeline: 'companion/getTimeline'
    }),
    formatNumber,
    formatDate,
    async refresh(type) {
      this.$mxp.track(`companion_valuation_actions_refresh_${type}`)
      try {
        this.$wait.start(type)
        const { mileage, vrm, namaGrade } = this
        const date = type === 'mileage' ? this.date : null
        const options = { forceEstimate: mileage.isEstimated }

        await companion.value({ vrm, date, namaGrade, options })
      } finally {
        this.$wait.end(type)
      }
    },
    async getValuationPDF() {
      const vehicleDetails = {
        vrm: this.vrm,
        vehicleDescription: this.data.overview.description,
        mileage: this.mileage.value,
        valuationDate: this.date,
        marketValue: this.data.overview.retail,
        tradeValue: this.data.overview.remarket
      }

      const similarListingFilters = {
        listingType: this.request.filter?.listing,
        sellerType: this.request.filter?.dealer,
        registration: this.request.filter?.year,
        mileage: this.request.filter?.mileage,
        colour: this.request.filter?.colour,
        distance: this.request.location,
        options: this.request.filter?.keyword,
        sortBy: this.sortBy?.label
      }

      const request = {
        vehicleDetails,
        listings: this.listings,
        similarListingFilters,
        total: this.total,
        customerReference: this.customerReference,
        organisation: this.organisation
      }

      try {
        this.$wait.start('valuationPdf')
        const pdf = getValuationPdfMapping({ request })
        const pdfs = [pdf]
        await getBatchPdfs(pdfs, vehicleDetails.vrm, 'file')
      } catch (e) {
        this.$mxp.track('companion_download_full_PDF_failed')
        this.$notify('Failed to export PDF')
      } finally {
        this.$wait.end('valuationPdf')
      }
    },
    async getCompanionPDF() {
      if (this.timeline === null) {
        try {
          await this.getTimeline(this.$route.params.uuid)
        } catch (e) {
          this.$notify('Failed to fetch timeline')
          return
        }
      }

      const vehicleDetails = {
        vrm: this.vrm,
        vehicleImage: this.photos[0],
        vehicleDescription: this.data.overview.description,
        mileage: this.mileage.value,
        valuationDate: this.date,
        condition: this.namaGrade,
        marketValue: this.data.overview.retail,
        tradeValue: this.data.overview.remarket,
        originalValue: this.data.overview.ocn?.onTheRoadPrice
      }

      const similarListingFilters = {
        listingType: this.request.filter?.listing,
        sellerType: this.request.filter?.dealer,
        registration: this.request.filter?.year,
        mileage: this.request.filter?.mileage,
        colour: this.request.filter?.colour,
        distance: this.request.location,
        options: this.request.filter?.keyword,
        sortBy: this.sortBy?.label
      }

      const request = {
        vehicleDetails,
        specs: this.specs,
        customerReference: this.customerReference,
        listings: this.listings.slice(0, 8),
        timeline: this.timeline,
        similarListingFilters,
        total: this.total,
        organisation: this.organisation
      }

      if (this.listing && this.listing.adText) {
        request.listing = this.listing
      }

      try {
        this.$wait.start('companionPdf')
        const pdf = getCompanionPdfMapping({ request })
        const pdfs = [pdf]
        await getBatchPdfs(pdfs, vehicleDetails.vrm, 'file')
      } catch (e) {
        this.$mxp.track('companion_download_full_PDF_failed')
        this.$notify('Failed to export PDF')
      } finally {
        this.$wait.end('companionPdf')
      }
    },
    openModal(id) {
      this.$mxp.track(`companion_valuation_actions_open_${id}_modal`)
      this.$modal.open(id)
    }
  }
}
</script>
