const IMAGE_BASE_URL = 'https://fuel-economy-pdf-images.s3.eu-west-2.amazonaws.com/'
const PERCAYSO_IMAGE = 'percayso-vehicle-intelligence.svg'
const MOTORS_IMAGE = 'motors-percayso.svg'
const DEFAULT_RESELLER_NAME = 'cazana'

export function formatNumberWithCommas(value) {
  if (isNaN(value)) return NaN
  return value.toLocaleString('en-GB')
}

export function capitalizeFirstLetterOfString(string) {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : 'N/A'
}

export function calculateMonthsAgoFromDate(dateString) {
  const currentDate = new Date()
  const inputDate = new Date(dateString)

  const monthsAgo =
    (currentDate.getFullYear() - inputDate.getFullYear()) * 12 +
    currentDate.getMonth() -
    inputDate.getMonth()

  return monthsAgo
}

export const getFormattedCondition = (condition) => {
  if (!condition) return 'N/A'
  return condition === 4 ? 'Poor' : 'Good'
}

export function capitalizeFirstLetter(string) {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : 'N/A'
}

export const getBrandedLogoUrl = (organisation) => {
  const { logoUrl, useCobrandedReports } = organisation;
  if (logoUrl && useCobrandedReports) {
    return logoUrl;
  }
  return null;
}

export const getReseller = (organisation) => {
  const { reseller } = organisation;

  if (reseller === 'motors') {
    return {
      reseller,
      title: 'Motors',
      logo: `${IMAGE_BASE_URL}${MOTORS_IMAGE}`,
    };
  }

  return {
    reseller: DEFAULT_RESELLER_NAME,
    title: 'Percayso (PVI)',
    logo: `${IMAGE_BASE_URL}${PERCAYSO_IMAGE}`
  };
}