export function formatDateToDDMMYYYY(date) {
  if (!date) return 'N/A'

  const [year, month, day] = date.split('T')[0].split('-')
  return `${day}/${month}/${year}`
}

export function formatDateToLongString(inputDate) {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]

  const date = new Date(inputDate)
  const day = date.getDate()
  const month = months[date.getMonth()]
  const year = date.getFullYear()

  const daySuffix = getDaySuffixForDate(day)

  return `${day}${daySuffix} ${month} ${year}`
}

export function getDaySuffixForDate(day) {
  if (day >= 11 && day <= 13) {
    return 'th'
  }
  switch (day % 10) {
    case 1:
      return 'st'
    case 2:
      return 'nd'
    case 3:
      return 'rd'
    default:
      return 'th'
  }
}

export function getMonthsBetweenDates(dateString) {
  const currentDate = new Date()
  const inputDate = new Date(dateString)

  const monthsAgo =
    (currentDate.getFullYear() - inputDate.getFullYear()) * 12 +
    currentDate.getMonth() -
    inputDate.getMonth()

  return monthsAgo
}
