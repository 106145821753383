import dayjs from 'dayjs'
import Mustache from 'mustache'
// eslint-disable-next-line import/no-webpack-loader-syntax
import companionPdfString from '!!raw-loader!./companionFullPdf.html'
import headerTemplate from '!!raw-loader!@/modules/pdfs/components/Header.html'
import marketOverviewTemplate from '!!raw-loader!@/modules/pdfs/components/MarketOverview.html'
import vehicleInformationCardTemplate from '!!raw-loader!@/modules/pdfs/components/VehicleInformationCard.html'
import vehicleOverviewCardTemplate from '!!raw-loader!@/modules/pdfs/components/VehicleOverviewCard.html'
import vehicleSpecificationColumnTemplate from '!!raw-loader!@/modules/pdfs/components/VehicleSpecificationColumn.html'
import vehicleTimelineColumnTemplate from '!!raw-loader!@/modules/pdfs/components/VehicleTimelineColumn.html'
import VehicleAdvertFullSectionTemplate from '!!raw-loader!@/modules/pdfs/components/VehicleAdvertFullPage.html'
import recentlistingColumnTemplate from '!!raw-loader!@/modules/pdfs/components/RecentListingColumn.html'
import {
  formatDateToDDMMYYYY,
  formatDateToLongString,
  getMonthsBetweenDates
} from '@/modules/pdfs/utils/dateUtils'
import {
  formatNumberWithCommas,
  getFormattedCondition,
  capitalizeFirstLetter,
  getReseller
} from '@/modules/pdfs/utils/UtilityFunctions'
import { getBatchPdfs } from '@/services/PdfService'

const FALLBACK = 'N/A'

export const getBatchPdf = (pdfs) => {
  const mappedPdfData = pdfs.map((pdf) => getPdfMapping(pdf))
  getBatchPdfs(mappedPdfData, 'companion')
}

const transformedListing = (listing) => ({
  listinglastSeen: getMonthsBetweenDates(listing.soldDate),
  listingOriginalPrice: formatNumberWithCommas(listing.listingOriginalPrice) || FALLBACK,
  listinglastPrice: formatNumberWithCommas(listing.listingPrice) || FALLBACK,
  listingDaysonMarket: listing.daysOnSale,
  listingDateListed: formatDateToDDMMYYYY(listing.firstSeen),
  listingMileage: formatNumberWithCommas(listing.mileage) || FALLBACK,
  listingSeller: listing.dealerName,
  listingAdText: listing.adText
})

const transformedListings = (listings) =>
  listings.map((listing) => ({
    listingVrm: listing.vrm,
    listingYear: listing.year,
    listingColour: listing.colour,
    listingSeller: listing.dealer.type,
    listingMileage: listing.mileage.display,
    listingPrice: listing.price.display,
    listingSellerName: listing.dealer.name,
    listingTimeOnMarket: listing.firstSeen
  }))

const transformedSpecs = (specs) => ({
  type: specs.type,
  manufacturer: specs.make,
  model: specs.model,
  trim: specs.trim,
  year: specs.year,
  registrationDate: formatDateToDDMMYYYY(specs.registrationDate),
  bodyType: specs.bodyStyle,
  transmission: specs.transmission,
  doors: specs.doors,
  fuelType: specs.fuelType,
  engineSize: specs.engineSizeCc,
  valves: specs.numberValves,
  cylinders: specs.numberCylinders,
  gears: specs.gears,
  engineNumber: specs.engineNumber,
  powerOutputKw: specs.powerOutputKw,
  powerOutputBhp: specs.powerOutputBhp,
  currentColour: capitalizeFirstLetter(specs.currentColour),
  vehicleAge: specs.firstRegistration,
  vehicleSeats: specs.seats
})

const transformHistory = (request) => {
  const transformedHistory = request.timeline.events.map((event) => {
    const {
      type,
      date,
      passed,
      odometer,
      rfcAndComments,
      listings,
      previousVRM,
      currentVRM
    } = event

    if (type === 'Mot') {
      return {
        motDate: formatDateToLongString(date),
        motStatus: passed ? 'Passed' : 'Failed',
        motStatusColour: passed
          ? 'fa-solid fa-circle-check text-green-600'
          : 'fa-solid fa-circle-xmark text-red-600',
        motMileage: formatNumberWithCommas(odometer.value) || FALLBACK,
        motComments: rfcAndComments.map((comment) => comment.text)
      }
    }

    if (type === 'Sale') {
      const saleListings = listings.map((listing) => {
        const { firstSeen, images, price, mileage, adText } = listing

        return {
          advertisementDate: formatDateToLongString(firstSeen),
          advertisementImage: images?.[0],
          advertisementPrice: formatNumberWithCommas(price) || FALLBACK,
          advertisementMileage: formatNumberWithCommas(mileage) || FALLBACK,
          advertisementAdText: adText
        }
      })

      const advertisementDateRange = `${formatDateToLongString(
        date
      )} - ${formatDateToLongString(event.lastSeen)}`

      return {
        advertisementDateRange,
        saleListings
      }
    }

    if (type === 'PlateChange') {
      return {
        plateChangeDate: formatDateToLongString(date),
        plateChangePrevious: previousVRM,
        plateChangeCurrent: currentVRM
      }
    }

    if (type === 'KeeperChange') {
      return {
        keeperChangeDate: formatDateToLongString(date)
      }
    }

    return null
  })

  return transformedHistory.filter((event) => event)
}

const transformedSimilarListingFilters = (similarListingFilters) => {
  const {
    listingType,
    sellerType,
    registration,
    mileage,
    colour,
    distance,
    options
  } = similarListingFilters

  const transformedFilters = {
    listingTypes: listingType
      .map((type) => capitalizeFirstLetter(type))
      .join(', '),
    sellerTypes: sellerType
      .map((type) => capitalizeFirstLetter(type))
      .join(', '),
    registrations: registration.map((registration) => registration).join(', '),
    mileages:
      mileage.length > 0
        ? `${formatNumberWithCommas(mileage[0])} - ${formatNumberWithCommas(
            mileage[1]
          )}`
        : '',
    colours: colour.map((colour) => capitalizeFirstLetter(colour)).join(', '),
    distance:
      distance?.coords?.lat && distance?.coords?.lon
        ? `${distance.distance} miles`
        : '',
    options: options.map((option) => capitalizeFirstLetter(option)).join(', ')
  }
  return transformedFilters
}

export const getCompanionPdfMapping = (pdfDataObject) => {
  const { request } = pdfDataObject

  try {
    const listing = request.listing && transformedListing(request.listing)
    const listings = transformedListings(request.listings)
    const specs = transformedSpecs(request.specs)
    const carHistory = transformHistory(request)
    const similarListingFilters = transformedSimilarListingFilters(
      request.similarListingFilters
    )
    const containsSalesEvents = request.timeline.events.some(
      (event) => event.type === 'Sale'
    )
    const reseller = getReseller(request.organisation)

    const pdfData = {
      vrm: request.vehicleDetails.vrm,
      vehicleImage: request.vehicleDetails.vehicleImage,
      vehicleDescription: request.vehicleDetails.vehicleDescription || FALLBACK,
      mileage:
        formatNumberWithCommas(request.vehicleDetails.mileage) || FALLBACK,
      valuationDate:
        formatDateToDDMMYYYY(request.vehicleDetails.valuationDate) || FALLBACK,
      condition:
        getFormattedCondition(request.vehicleDetails.condition) || FALLBACK,
      marketValue:
        formatNumberWithCommas(request.vehicleDetails.marketValue) || FALLBACK,
      tradeValue:
        formatNumberWithCommas(request.vehicleDetails.tradeValue) || FALLBACK,
      originalValue:
        formatNumberWithCommas(request.vehicleDetails.originalValue),
      firstRegistration:
        formatDateToLongString(request.timeline.firstRegistration) || FALLBACK,
      ...specs,
      containsSalesEvents,
      isHistoricalValuationDate: !dayjs().isSame(
        dayjs(request.vehicleDetails.valuationDate),
        'day'
      ),
      sortBy: request.similarListingFilters.sortBy,
      listings,
      carHistory,
      similarListingFilters,
      totalSimilarListings: request.total,
      customerReference: request.customerReference
    }

    if (listing) {
      pdfData.listing = listing
    }

    const headerSection = Mustache.render(headerTemplate, {
      title: 'Companion Report',
      mainLogo: reseller.logo,
    })
    
    const marketOverviewSection = Mustache.render(
      marketOverviewTemplate,
      pdfData
    )

    const vehicleInformationCardSection = Mustache.render(
      vehicleInformationCardTemplate,
      pdfData
    )

    const vehicleOverviewCardSection = Mustache.render(
      vehicleOverviewCardTemplate,
      pdfData
    )

    const vehicleSpecificationColumnSection = Mustache.render(
      vehicleSpecificationColumnTemplate,
      pdfData
    )

    const vehicleTimelineColumnSection = Mustache.render(
      vehicleTimelineColumnTemplate,
      pdfData
    )

    const VehicleAdvertFullPageSection = Mustache.render(
      VehicleAdvertFullSectionTemplate,
      pdfData
    )

    const recentlistingColumnSection = Mustache.render(
      recentlistingColumnTemplate,
      pdfData
    )

    const pdfString = Mustache.render(companionPdfString, pdfData, {
      headerSection,
      marketOverviewSection,
      vehicleInformationCardSection,
      vehicleOverviewCardSection,
      vehicleSpecificationColumnSection,
      vehicleTimelineColumnSection,
      VehicleAdvertFullPageSection,
      recentlistingColumnSection
    })

    const pdfName = `${reseller.title} - ${request.vehicleDetails.vrm} - Companion Report`

    return {
      pdfString,
      vrm: request.vehicleDetails.vrm,
      pdfName,
    }
  } catch (error) {
    throw new Error(`Error generating Companion PDF: ${error.message}`)
  }
}
